.home-container {
    margin-left: 12vw;
    margin-right: 12vw;
    margin-top: 16px;
}

.banner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10vh;
}

.parts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 35vw;
    margin-bottom: 10vh;
    gap: 3vh;
}

.banner-image-wrapper {

    object-fit: fit;
    overflow: hidden;
}

.banner-heading {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.description {
    margin-bottom: 15px;
}

.count-box {
    display: flex;
    gap: 5vw;
}

.count {
    display: flex;
    flex-direction: column;
    gap: 1vh;

}

.categories {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading-boarder {
    height: 5px;
    background-color: #01AEEF;
    width: 84px;
    border-radius: 2rem;
}

.tiles-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1080px;
    gap: 50px;
    justify-content: center;
}

.tile {
    display: flex;
    width: 100%;
    /* Ensure the tile's width is always 100% of its container */
    aspect-ratio: 1 / 1;
    /* Adjust this to your desired aspect ratio, e.g., 16 / 9 for a widescreen aspect ratio */
    flex-direction: column;
    cursor: pointer;
    max-width: 300px;
    height: 425px;
    color: #000;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.22);
    box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.22);
    transition: background-color 0.3s;
}

.tile:hover {
    background-color: #000;
    color: #fff;
    height: auto;

}


@media (max-width: 800px) {
    .tiles-wrapper {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}

.product-name {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 14px;
    padding: 18px;
}
.product-name p {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limits the text to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 3.0em; /* Adjust based on your line height (e.g. 1.5em * 3 lines) */
    line-height: 1.5em;
    transition:height 0.2s ease-in-out;
    
}

.tile:hover .product-name p {
    -webkit-line-clamp: unset; /* Remove the line clamp on hover */
    height: 6em; /* Adjust this for the number of lines you want on hover */
}

.view-more-button {
    display: flex;
    align-items: center;
    margin: 50px;
}

.view-more-button button {
    border: none;
    color: aliceblue;
    background-color: #285789;
    padding: 12px 25px;
    border-radius: 2px;
}

.latest-arrivals-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 6vh;

}

.latest-arrivals-parts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35vw;
    gap: 4.5vh;
}

.arrivals-image-wrapper {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.arrivals-image-wrapper img {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.read-more-button {
    display: flex;
    align-items: center;

}

.read-more-button button {
    border: none;
    color: aliceblue;
    background-color: #007A87;
    padding: 12px 25px;
    border-radius: 2px;
    transition: background-color 0.2s;
}

.read-more-button button:hover {
    background-color: #005058;
}

/* Scroll animation*/
.home-box {
    opacity: 0;
    transition: opacity 2s ease-out;
}

.home-box.visible {
    opacity: 1;
}

@media(min-width:1920px) {
    .home-container {
        margin-left: 18vw;
        margin-right: 18vw;
    }

    .banner {
        gap: 5vw;
    }
}

@media(max-width:900px) {
    .banner {
        flex-direction: column;
        margin-top: 30px;
    }

    .parts {
        flex-grow: 1;
        align-items: stretch;
        width: 100%;
    }

    .latest-arrivals-parts {
        flex-grow: 1;
        align-items: stretch;
        width: 100%;
        margin-bottom: 3vh;
    }

    .banner h1 {
        font-size: 2.0rem;

    }

    .latest-arrivals-wrapper {
        flex-direction: column;
        margin-top: 30px;
    }

    .count-box {
        justify-content: center;
        gap: 8vw;
    }

    .count {
        align-items: center;
    }


    .home-container {
        margin-left: 6vw;
        margin-right: 6vw;
    }
}