.miviStyle{
    display: flex;
    gap: 35px;
    justify-content: space-between
}

.boxStyle {
    flex: 1;          
    min-width: 0;      
    height: auto;     
    padding: 20px;
    box-sizing: border-box; 
    border: 1px solid #ddd; 
    border-radius: 8px;      
  }

  .imgWrapper {
    display: flex;
    justify-content: center;
  }
  .aboutImage {
    border: 1px solid #ddd;
    border-radius: 8px; 
    width: 100%;
    max-width: 1000px;
  }


  @media (max-width:900px){
    .miviStyle{
        flex-direction: column;
        align-items: center;
        width:100%;
    }

}