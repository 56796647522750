

.container-admin-page{
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    background-color: #eaeaec;

}

.sidebar {
    margin: 0;
    padding: 0;
    width: 200px;
    flex: 0 0 auto;
    background-color: #fff;
    height: 100%;
    overflow: auto;
    border: solid 1px #E7E7E7;
    display: flex;
    flex-direction: column;

  }
  .sidebar p{
      font-weight:600;
      padding-left: 16px;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom:solid 1px #E7E7E7;
  }
  .sidebar div, .sidebar button {
    font-weight:400;
    display: flex;
    align-items: center;
    color: black;
    padding: 16px;
    margin:0;
    text-decoration: none;
    transition: background-color 0.3s;
    border-bottom:solid 1px #E7E7E7;

    cursor: pointer;
  }

  .icon-alignment {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: none;
    border-radius: 0;
    gap: 10px;
  }

  .icon-properties{
    font-size: 20px;
  }


  .sidebar button.active{
    background-color: #285789;
    color: white;
  }
  
  .sidebar div:hover:not(.active) {
    background-color: #eaeaec;
  }
  
  .content {
    background-color: #fff;
    margin-left: 10px;
    width: 35vw;
  }
  

  .product-form-layout{
    padding: 15px;
    display: flex;
    flex-direction: column;
    row-gap:20px;
  }

  .product-form-layout input[type="text"], .product-form-layout input[type="submit"], select, .form-button{
    padding: 10px;
    border: none;
    border: 1px solid #cccccc;
    border-radius: 3px;
  }

  .form-button{
    border: 1px solid #285789;
    background-color: white;
    color: #285789;
    font-weight:500;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .product-form-layout input[type="submit"]{
    border: none;
    background-color: #285789;
    color: white;
    font-weight:500;
    font-size: 16px;
    transition: background-color 0.3s;
  }

  input[type="submit"]:hover{
    background-color: black;
    cursor: pointer;
  }

  .form-button:hover{

    background-color: black;
    color: white;
    cursor: pointer;
    border: none;
    padding: 11px;
  }
  .file-controls{
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .file-inputs-wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    width: 100%;
  }


  .image-upload-controls-wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;

  }

  .specifications{
    border: 1px #ccc solid;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }


  @media screen and (max-width: 800px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
    .sidebar a {float: left;}
    .content {margin-left: 0; margin-top: 50px; width: 100%;}
    .file-controls{flex-direction: column;}
    .container-admin-page{flex-direction: column; padding:10px; overflow: hidden;}
  }
  
  @media screen and (max-width: 400px) {
    .sidebar a {
      text-align: center;
      float: none;
    }
  }