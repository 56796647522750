.carousel {
    position: relative;
    max-width: 1920px;
    max-height: 800px;
    overflow: hidden;
  }
  
  .carousel-images {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-images img {
    width: 100%;
    flex-shrink: 0;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
  }
  
  .carousel-button.prev {
    left: 10px;
  }
  
  .carousel-button.next {
    right: 10px;
  }

  .carousel-dots {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 10px;
  }
  
  .dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 7px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  .dot.active {
    background-color: rgba(255, 255, 255, 1);
  }