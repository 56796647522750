.product-container{
    margin-left:12vw;
    margin-right:12vw;
   margin-top: 30px;
}
.product-path-sort{
    display: flex;
    justify-content: end;
    margin-bottom: 30px;
    
}
.product-path{
    display: flex;
    align-items: center;
}
.product-path p{
    display: flex;
    align-items: center;
    font-size: 17px;
    color: #646464;
}

.product-sort{
    display: flex;
    align-items: center;
    gap:8px;
}
/* Sort dropdown*/
.dropdown{
    position: relative;
    display: inline-block;
}
.dropbtn {
    background-color: white;
    padding:8px 10px;
    width:120px;
    border:1px solid #B9B9B9;
    cursor: pointer;
    text-align: left;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
}
.dropdown-content{
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
.dropdown-content p{
    color: black;
    padding: 5px;
    text-decoration: none;
    display: block;
    font-size: 0.9rem;
    cursor:pointer;
} 
.dropdown-content p:hover{
    background-color: #eee;
}
.dropdown .dropdown-content {
    display: block;
}
.chevron {
    cursor: pointer;
    transition: transform 0.5s ease;
} 
.rotate {
    transform: rotate(-180deg);
}
/* Sidebar and product tiles CSS*/
.product-sidebar{
    margin: 0;
    padding: 0;
    width: 400px;
    background-color: #fff;
    height: 100%;
    border: solid 1px #E7E7E7;
    display: flex;
    flex-direction: column;
}
.product-sidebar p{
    font-weight:600;
    padding-left: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #E7E7E7;
    line-height: 2;
}
.product-sidebar button {
    font-weight:400;
    font-size: 16px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: black;
    padding: 15px 10px 15px 10px;
    margin:0;
    text-decoration: none;
    transition: background-color 0.3s;
    border-bottom:solid 1px #E7E7E7;
    cursor: pointer;
    
    background-color: white;
}

/*.product-sidebar button a{
    margin:0;
    color: black;
    text-decoration: none;
}*/
.product-sidebar button.active{
    color: white;
}

.product-sidebar button.active{
    background-color: #007A87;
    color: white;
}
  
.product-sidebar button:hover:not(.active) {
    background-color: #eaeaec;
}
.sidebar-tiles-wrapper{
    display: flex;
    gap: 20px;
}



.sub_cat_wrapper_l{
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
    height: 0px;
    max-height: 400px;
    overflow: hidden;
    transition: height 0.3s;
    
}

.sub_cat_wrapper_l_active{
    height: auto;
}

.sub_cat_wrapper_l .sub_anchor_style{
    font-size: 0.9rem;
    color: #494949;
    padding: 10px;
    padding-left: 20px;
    cursor: pointer;
    transition: 0.2s;
}

.sub_cat_wrapper_l .sub_anchor_style:hover{
    background-color: #eaeaec;
}

.sub_cat_wrapper_l .sub_anchor_style.active{
    font-weight: bold;
    background-color: #eaeaec;
}



.product-tiles-wrapper{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 20px; /* This sets both row and column gaps */
    justify-content: center; /* Aligns the grid to the center of its container */
    /*height: fit-content;*/
}

.product-tiles-wrapper-no-product{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 60px;
}
.pagination-div{
    padding: 10px;
    border: 1px solid #D6D6D6;
    background-color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.pagination-div:first-child {
    border-top-left-radius:3px;
    border-bottom-left-radius:3px;
}

.pagination-div:last-child {
    border-top-right-radius:3px;
    border-bottom-right-radius:3px;
}

.pagination-div:hover{
    background-color: #eee;
}
.pagination-div.active{
    background-color: #007A87;
    border: 1px solid #007A87;
    color: white;
}

@media(min-width:1920px) {
    .product-container{
        margin-left:18vw;
        margin-right:18vw;
    }
}

@media (max-width: 1000px) {
    .product-tiles-wrapper {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
  }
  
  @media (max-width:900px) {
    .product-tiles-wrapper {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .product-path-sort{
        justify-content: space-between;
    }
    .product-sidebar{
        display: none;
    }

    .product-container{
        margin-left:6vw;
        margin-right:6vw;
    }
  }
  
  