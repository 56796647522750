.container{
    margin-left:13vw;
    margin-right:13vw;
    
}
.header{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin-top:2vh;
    margin-bottom:2vh;
}
.box{
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
}
.logo{
    padding-top: 10px;
    padding-bottom: 10px;
    /*width:270px;
    height:65px;*/
}
/*.box:nth-child(2){
    justify-content: left;
}*/
.box:nth-child(1){
    justify-content: left;
}
.box:nth-child(3){
    justify-content: right;
    gap: 30px;

}
.search{
    display: flex;
    justify-content: left;
    position:relative;
    width: 100%;
    gap:10px;
    min-width: 300px;
}
.search input[type="text"]{
    padding: 10px;
    padding-left: 30px;
    border: 1px solid #ccc;
    border-radius: 32px;
    min-width:19vw;
    width:100%;
    outline: none;
  }

.search svg {
    position: absolute;
    top:50%;
    left:10px;
    transform: translateY(-50%);
}
.contact{
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 10px;
}
.icon{
   display: flex;
   align-items: center;
}

.cont-content{
   font-family: 'Inter', sans-serif;
   font-size: 13px;
   text-align: center;
}

.cont-content a{
    color: #000;
    font-size: 0.9rem;
    
 }
 .dropdown-list {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    list-style: none;
    margin: 0;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 400px;
    overflow-y: auto;
    z-index: 100000;
  }

  .dropdown-list li{
    padding: 10px;
    cursor: pointer;
  }

  .dropdown-list li:hover{
    background-color: #eee;
  }
  
.line{
    border-bottom: 0.3px solid #ccc;
    
}
.navbar{
    padding: 20px;
    display: flex;
    justify-content:center;
    align-items: center;
}
.nav-list{
    list-style:none;
    margin:0;
    padding:0;
    display:flex; 
}

.nav-list a{
    color:#000;
    font-size:15px;
    font-weight: 400;
    cursor: pointer;
    margin-right:40px;
    display:flex;
    align-items: center;
    transition: font-size 0.2s;
}
/*
.nav-list a:hover{
    font-size: 18px;
}*/
.nav-btn{
    cursor: pointer;
    padding:5px;
    visibility: hidden;
    opacity: 0;
}

@media (max-width:900px){
    #line-below-nav-bar{
        display: none;
    }
    .header{
        flex-direction: column;
        gap:2vh;
    }

    .box:nth-child(1){
        justify-content: center;
    }

    .container{
        margin-left:10vw;
        margin-right:10vw;
    }

    .box:nth-child(3){
        justify-content: center;
        gap: 20px;
    }
}

@media (max-width:900px){
    
    .navbar-wrapper{
        height: 0;
    }
    .nav-btn{
        visibility: visible;
        opacity: 1;
        font-size: 1.8rem;
        background: transparent;
    }
    .nav-list{
        position: fixed;
        z-index: 999;
        top: 0;
        left:0;
        height: 80%;
        width: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(191, 193, 193, 0.91);
        gap:1.5rem;
        transition:width 0.3s;
        overflow: hidden;
    }
    .responsive_nav{
        width:100%
    }
    .nav-close-btn{
        position: absolute;
        top:2rem;
        right: 2rem;
    }
    .nav-list a{
        font-size: 1.2rem;
    }
    .container{
        margin-left:6vw;
        margin-right:6vw;
        
    }
   
}