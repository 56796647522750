.wishlisted-product{
    margin-top:1.5vh;
    
}
.details-icon-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5vh;
    
}
.wishlist-product-details{
    display: flex;
    align-items: center;
    gap:15px;
}
.wishlist-product-image img{
    display: flex;
    align-items: center;
}
.wishlist-product-label{
    display: flex;
    flex-direction: column;
    align-items: left;   
    justify-content: center;
}
.delete-icon{
    margin-right: 1vw;
    cursor: pointer;
    color: #a4a2a2;
}
.delete-icon:hover{
    color: #323131;
}
.break-line{
    border-bottom: 0.3px solid #ccc;
}