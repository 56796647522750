.anchor-style{
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 24px;
    font-weight: 500;
    text-align: end;
    cursor: pointer;
    color: #494949;
    transition: color 0.3s, font-size 0.3s, background-color 0.3s;
}


.anchor-style:hover{
    color: #000;
}
.anchor-style.active{
    font-size: 40px;
    font-weight: 700;
    color: #007A87;
}

.icon-properties{
    font-size: 20px;

}

.contact-us-button{
    padding: 10px;
    position:absolute;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    bottom: 4vh;
    left: 8vw;
    color: #000;
    width: 200px;
    margin-top:30px;
    background-color:#ffc438;
    transition:background-color 0.3s;
    border-radius: 3px;
}
.contact-us-button .anchor-style{
    color: inherit;
    font-size: 1.3rem;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.contact-us-button:hover{
    background-color:#000;
    color: #fff;
}

.sub-cat-wrapper{
    padding-right: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction:column;
    gap: 10px;
    align-items:end;
    overflow: hidden;
    height: 0;
    transition: height 0.5s;
}


.sub_wrapper_active{
    height: auto;
    border-bottom: 1px solid #c5c5c5;
    padding-bottom: 16px;
}

.sub-cat-wrapper .anchor-sub-style{
    cursor: pointer;
    color: #4e4e4e;
    transition: color 0.2s;
}

.sub-cat-wrapper .anchor-sub-style:hover{
    color: #000;
    font-weight: bold;
}

.sub-cat-wrapper .anchor-sub-style.active{
    color: #000;
    font-weight: bold;
}