.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure modal is above other content */
  }
  
  .modal-content {
    display: flex;
    position: relative; /* Needed for absolute positioning of children */
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-height: 80vh;
    overflow: hidden;
    overflow-y: scroll;
  }
  
  
  .modal-close-button {
    position: absolute;
    top : 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    /* Style the button as needed, e.g., make it look like a close (X) button */
    font-size: 1.5em;
  }