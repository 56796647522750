


.login-form-container{
    margin-top:7vh;
    margin-bottom:7vh;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.login-form-wrapper{
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vh;
    padding-top: 5vh;
    box-sizing: border-box;
    border: 1px solid rgb(218, 218, 218);
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.form-inputs{
    padding: 10px;
    width: 100%;
    height: 45px;
    border: 1px solid rgb(218, 218, 218);

}

.login-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    text-justify: center;
    padding: 10px;
    width: 100%;
    background-color:#285789 ;
    color: white;
    cursor: pointer;
    height: 45px;
}