body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,a,li,input,label,textarea,button,td,address{
  font-family: 'Barlow', sans-serif;
}

h1,h2,ul,h3,h4,h5,h6{
  font-family: 'Nunito', sans-serif;
}

h1{
    margin: 0px;
    font-weight:700;
    font-size: 2.3rem;
}
h2{
  margin: 0px;
  font-weight:700;
  font-size: 2.1rem;
  text-align: center;
  line-height: 1;
}
h3{
  margin:0px;
  font-size:1.9rem;
  font-weight: 700;
  line-height:3rem ;
}
h4{
  font-size:1.5rem;
  margin:0px;
  
}

h5{
  font-size:1.1rem;
  margin:0px;
}
h6{
  font-size: 0.9rem;
  margin:0px;
}
a{
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
}
ul{
  padding:0px;
  margin: 0px;
  list-style: none;
}
li, p{
  line-height: 1.7;
  margin: 0px;
  font-size: 0.9rem;
}
button{
  border:none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*,
*::before,
*::after {
    box-sizing: border-box;
}

/*
Important colours
 #007A87; Teal.
 #285789;  A darkNavyBlue
}*/