.form-component{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35vw;
    margin-bottom: 50px;
    gap:25px;
}
.form-input{
    border:none;
    border:1px solid #D2D2D2;
    width:100%;
    height: 40px;
    padding: 10px;
}
.name-phone{
    display: flex;
    justify-content: space-evenly;
    column-gap: 10px;
}
.message-button{
    display: flex;
    align-items: center;
    
}
.message-button button{
    border: none;
    color: aliceblue;
    background-color: #007A87;
    height: 40px;
    width: 100%;
    transition: background-color 0.3s;
    cursor: pointer;
}

.message-button button:hover{
    background-color: #01565f;
}
.form-textarea{
    height: 297px;
    max-width:100%;
    min-width:100%;
}

@media (max-width:900px){
    .form-component{
        width:100%;
    }
    .form-textarea{
        height: 150px;
    }
   
}
@media (max-width:700px){
    .name-phone{
        flex-direction: column;
        gap:25px;
    }
}