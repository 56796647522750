.wishlist-container{
    margin-left:12vw;
    margin-right:12vw;
    margin-top: 2vh;
}
.wislist-heading{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 1vh;
    margin-top: 2vh;
}
.wislist-heading p{
    font-weight: 500;
}
.break-line{
    border-bottom: 0.3px solid #ccc;
}
.wishlist-sub-heading{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 1vh;
    margin-top: 3vh;
}
.wishlisted-product{
    margin-top:1.5vh;
    
}

.wishlist-product-image img{
    display: flex;
    align-items: center;
}
.wishlist-product-details{
    display: flex;
    align-items: center;
    gap:15px;
}
.wishlist-product-label{
    display: flex;
    flex-direction: column;
    align-items: left;   
    justify-content: center;
}


.browse-email-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4vh;
    margin-bottom: 4vh;
}
.browse-button{
    display: flex;
    align-items: center;
    gap:2px;
    transition:color 0.3s;
}
.browse-button:hover{
    color: #9b9999;
}
.email-button button{
    background-color: #007A87;
    color:#ffffff;
    padding: 20px 30px;
    border-radius: 3px;
    cursor: pointer;
    transition:background-color 0.3s;
}

.email-button button:hover, .mail-form button:hover{
    background-color: #005963;
}
.mail-form{
   display: flex;
   flex-direction: column;
   gap:20px;
   margin-bottom: 30px;
}
.email-phone{
    display: flex;
    gap:20px;
}
.email-phone input{
    width:100%;
    padding:10px;
    border-radius: 4px;
    border:1px solid #ccc;
}
.mail-form textarea{
    width:100%;
    padding:10px;
    border-radius: 3px;
    border:1px solid #ccc;
    height: 100px;
}
.expanded-button{
    display: flex;
    justify-content: center;
}
.mail-form button{
    background-color: #007A87;
    color:#ffffff;
    padding: 15px 30px;
    border-radius: 3px;
    cursor: pointer;
    transition:background-color 0.3s;
}
@media (min-width:1920px){
    .wishlist-container{
        margin-left:18vw;
        margin-right:18vw;
    }
}


@media (max-width:600px){
    .email-phone{
        flex-direction: column;
        gap:2vh;
    }
    .mail-form{
        gap:2vh;
    }
}
@media (max-width:900px){
    .wishlist-container{
        margin-left:6vw;
        margin-right:6vw;
    }
}